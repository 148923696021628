import React, { createContext } from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as singleProjectStyles from "./project-template.scss"
import Layers from "../../components/layer-svg/Layers"
import LayerContext from "./LayerContext"

export default class ProjectTemplate extends React.Component {
  state = {
    layerState: {
      selectedLayer: 1,
    },
  }

  constructor() {
    super()
  }

  setLayer = layerNo => {
    this.setState(prevState => ({
      layerState: { selectedLayer: layerNo },
    }))
  }

  render() {
    const { layerState } = this.state
    const { setLayer } = this
    const {
      mdx: {
        frontmatter: { title, date },
        body,
      },
    } = this.props.data
    return (
      <Layout>
        <LayerContext.Provider value={{ layerState, setLayer }}>
          <div className={"single-project-wrapper"}>
            <article className={"project-info"}>
              <div>
                <h1>Project: {title}</h1>
                <p>{date}</p>
              </div>
              <MDXRenderer>{body}</MDXRenderer>
            </article>
            <div className={"project-image"}>
              <svg>
                <Layers />
              </svg>
            </div>
          </div>
        </LayerContext.Provider>
      </Layout>
    )
  }
}

export const query = graphql`
  query GetSingleProject($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        slug
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`
