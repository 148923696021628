import React from "react"
import classNames from "classnames"

export default class Layer extends React.Component {
  render() {
    const classes = classNames({
      st2: true,
      active: this.props.active,
    })

    return (
      <>
        <g className={'quader-layer'}>
          <polygon
            className="st0"
            points="204.11,263.73 9.11,154.2 9.11,128.92 204.11,238.45 		"
          />
          <polygon
            className="st1"
            points="204.11,263.73 399.11,154.2 399.11,128.92 204.11,238.45 		"
          />
          <polygon
            className={classes}
            points="9.11,128.92 204.11,8.55 399.11,128.92 204.11,238.45 		"
          />
        </g>
      </>
    )
  }
}
