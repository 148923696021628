import React, { useContext } from "react"
import * as layerStyles from "./layer-svg.scss"
import Layer from "./Layer"
import LayerContext from "../../templates/projects/LayerContext"

export default class Layers extends React.Component {
  static contextType = LayerContext

  constructor() {
    super()
  }

  render() {
    const { layerState, setLayer } = this.context

    const layers = [1, 2, 3, 4, 5, 6, 7, 8].reverse()
    return layers.map((value, index) => {
      return (
        <a
          style={{
            transform: `translateY(${Math.abs(layers.length - index) * 70}px)`,
          }}
          key={value}
          onClick={() => setLayer(value)}
        >
          <Layer active={layerState.selectedLayer === value} />
        </a>
      )
    })
  }
}
